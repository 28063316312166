// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cloud-spinner-spinner-6SxnJTnHmwr{margin-top:31px}.cloud-spinner-spinner-6SxnJTnHmwr>img{display:block}.cloud-spinner-circle-2W6VU7oemwr{width:90px;height:90px;animation:cloud-spinner-circle-2W6VU7oemwr 3s infinite cubic-bezier(0.165, 0.84, 0.44, 1);transform-origin:center center}@keyframes cloud-spinner-circle-2W6VU7oemwr{0%{transform:rotate(0);opacity:1}20%{transform:rotate(144deg);opacity:.6}40%{transform:rotate(216deg);opacity:.8}60%{transform:rotate(360deg);opacity:.6}80%{transform:rotate(288deg);opacity:.8}100%{transform:rotate(360deg);opacity:1}}.cloud-spinner-cloud-3eUNQAPlmwr{margin-top:-90px}", "",{"version":3,"sources":["webpack://client/shared/cloud-spinner/cloud-spinner.scss"],"names":[],"mappings":"AAEA,mCACE,eAAA,CAGF,uCACE,aAAA,CAGF,kCACE,UAAA,CACA,WAAA,CACA,yFAAA,CACA,8BAAA,CAEA,4CACE,GACE,mBAAA,CACA,SAAA,CAEF,IACE,wBAAA,CACA,UAAA,CAEF,IACE,wBAAA,CACA,UAAA,CAEF,IACE,wBAAA,CACA,UAAA,CAEF,IACE,wBAAA,CACA,UAAA,CAEF,KACE,wBAAA,CACA,SAAA,CAAA,CAKN,iCACE,gBAAA","sourcesContent":["@import '~@css/vars.scss';\n\n.spinner {\n  margin-top: 31px;\n}\n\n.spinner > img {\n  display: block;\n}\n\n.circle {\n  width: 90px;\n  height: 90px;\n  animation: circle 3s infinite cubic-bezier(0.165, 0.84, 0.44, 1);\n  transform-origin: center center;\n\n  @keyframes circle {\n    0% {\n      transform: rotate(0);\n      opacity: 1;\n    }\n    20% {\n      transform: rotate(144deg);\n      opacity: 0.6;;\n    }\n    40% {\n      transform: rotate(216deg);\n      opacity: 0.8;\n    }\n    60% {\n      transform: rotate(360deg);\n      opacity: 0.6;\n    }\n    80% {\n      transform: rotate(288deg);\n      opacity: 0.8;\n    }\n    100% {\n      transform: rotate(360deg);\n      opacity: 1;\n    }\n  }\n}\n\n.cloud {\n  margin-top: -90px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"spinner": "cloud-spinner-spinner-6SxnJTnHmwr",
	"circle": "cloud-spinner-circle-2W6VU7oemwr",
	"cloud": "cloud-spinner-cloud-3eUNQAPlmwr"
};
export default ___CSS_LOADER_EXPORT___;
