// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../styles/utils.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".autocomplete-autocomplete-3lfd1Wjvmwr{max-width:300px}input.autocomplete-input-3AJ92oX0mwr,.autocomplete-input-3AJ92oX0mwr input{max-width:100%}.autocomplete-autocomplete-input-wrapper-1snu0bYXmwr{flex:1}.autocomplete-sr-text-6fmlPcD9mwr{}", "",{"version":3,"sources":["webpack://client/shared/autocomplete/autocomplete.scss"],"names":[],"mappings":"AAAA,uCACE,eAAA,CAGF,2EACE,cAAA,CAGF,qDACE,MAAA,CAGF,kCACE","sourcesContent":[".autocomplete {\n  max-width: 300px;\n}\n\ninput.input, .input input {\n  max-width: 100%;\n}\n\n.autocomplete-input-wrapper {\n  flex: 1;\n}\n\n.sr-text {\n  composes: screen-reader-only from '~@css/utils.scss';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autocomplete": "autocomplete-autocomplete-3lfd1Wjvmwr",
	"input": "autocomplete-input-3AJ92oX0mwr",
	"autocomplete-input-wrapper": "autocomplete-autocomplete-input-wrapper-1snu0bYXmwr",
	"autocompleteInputWrapper": "autocomplete-autocomplete-input-wrapper-1snu0bYXmwr",
	"sr-text": "autocomplete-sr-text-6fmlPcD9mwr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-reader-only"] + "",
	"srText": "autocomplete-sr-text-6fmlPcD9mwr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["screen-reader-only"] + ""
};
export default ___CSS_LOADER_EXPORT___;
