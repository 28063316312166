// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../styles/utils.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".avatar-list-container-7KEn90Irmwr{display:flex;overflow:hidden}.avatar-list-container-7KEn90Irmwr>*{margin:0 4px}", "",{"version":3,"sources":["webpack://client/shared/avatar-list/avatar-list.scss"],"names":[],"mappings":"AAEA,mCAEE,YAAA,CACA,eAAA,CAEA,qCACE,YAAA","sourcesContent":["@import \"~@css/vars.scss\";\n\n.container {\n  composes: mask-right from '~@css/utils.scss';\n  display: flex;\n  overflow: hidden;\n\n  > * {\n    margin: 0 $space-xxs;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"container": "avatar-list-container-7KEn90Irmwr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["mask-right"] + ""
};
export default ___CSS_LOADER_EXPORT___;
