// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fake-button-fake-button-1YTXyGjjmwr{cursor:pointer}@media screen and (-ms-high-contrast: active){.fake-button-fake-button-1YTXyGjjmwr{color:buttonText}.fake-button-fake-button-1YTXyGjjmwr:focus{outline:2px dotted buttonText}}", "",{"version":3,"sources":["webpack://client/shared/fake-button/fake-button.scss"],"names":[],"mappings":"AAAA,qCACE,cAAA,CACA,8CAFF,qCAGI,gBAAA,CACA,2CACE,6BAAA,CAAA","sourcesContent":[".fake-button {\n  cursor: pointer;\n  @media screen and (-ms-high-contrast: active) {\n    color: buttonText;\n    &:focus {\n      outline: 2px dotted buttonText;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fake-button": "fake-button-fake-button-1YTXyGjjmwr",
	"fakeButton": "fake-button-fake-button-1YTXyGjjmwr"
};
export default ___CSS_LOADER_EXPORT___;
